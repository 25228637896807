// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-form-closet-outbrain-js": () => import("./../../../src/pages/form/closet/outbrain.js" /* webpackChunkName: "component---src-pages-form-closet-outbrain-js" */),
  "component---src-pages-form-closet-product-23-tcw-30-taboola-js": () => import("./../../../src/pages/form/closet/product/23/tcw-30/taboola.js" /* webpackChunkName: "component---src-pages-form-closet-product-23-tcw-30-taboola-js" */),
  "component---src-pages-form-closet-product-23-tcw-37-taboola-js": () => import("./../../../src/pages/form/closet/product/23/tcw-37/taboola.js" /* webpackChunkName: "component---src-pages-form-closet-product-23-tcw-37-taboola-js" */),
  "component---src-pages-form-closet-product-24-tcw-02-taboola-js": () => import("./../../../src/pages/form/closet/product/24/tcw-02/taboola.js" /* webpackChunkName: "component---src-pages-form-closet-product-24-tcw-02-taboola-js" */),
  "component---src-pages-form-closet-product-24-tcw-19-taboola-js": () => import("./../../../src/pages/form/closet/product/24/tcw-19/taboola.js" /* webpackChunkName: "component---src-pages-form-closet-product-24-tcw-19-taboola-js" */),
  "component---src-pages-form-closet-product-24-tcw-24-taboola-js": () => import("./../../../src/pages/form/closet/product/24/tcw-24/taboola.js" /* webpackChunkName: "component---src-pages-form-closet-product-24-tcw-24-taboola-js" */),
  "component---src-pages-form-closet-product-24-tcw-25-taboola-js": () => import("./../../../src/pages/form/closet/product/24/tcw-25/taboola.js" /* webpackChunkName: "component---src-pages-form-closet-product-24-tcw-25-taboola-js" */),
  "component---src-pages-form-closet-taboola-js": () => import("./../../../src/pages/form/closet/taboola.js" /* webpackChunkName: "component---src-pages-form-closet-taboola-js" */),
  "component---src-pages-form-closet-thank-you-js": () => import("./../../../src/pages/form/closet/thank-you.js" /* webpackChunkName: "component---src-pages-form-closet-thank-you-js" */),
  "component---src-pages-form-wall-bed-index-js": () => import("./../../../src/pages/form/wall-bed/index.js" /* webpackChunkName: "component---src-pages-form-wall-bed-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-partners-js": () => import("./../../../src/pages/marketing-partners.js" /* webpackChunkName: "component---src-pages-marketing-partners-js" */),
  "component---src-pages-ooa-js": () => import("./../../../src/pages/ooa.js" /* webpackChunkName: "component---src-pages-ooa-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-styleguide-colors-js": () => import("./../../../src/pages/styleguide/colors.js" /* webpackChunkName: "component---src-pages-styleguide-colors-js" */),
  "component---src-pages-styleguide-components-chat-bot-js": () => import("./../../../src/pages/styleguide/components/chat-bot.js" /* webpackChunkName: "component---src-pages-styleguide-components-chat-bot-js" */),
  "component---src-pages-styleguide-components-index-js": () => import("./../../../src/pages/styleguide/components/index.js" /* webpackChunkName: "component---src-pages-styleguide-components-index-js" */),
  "component---src-pages-styleguide-components-loader-js": () => import("./../../../src/pages/styleguide/components/loader.js" /* webpackChunkName: "component---src-pages-styleguide-components-loader-js" */),
  "component---src-pages-styleguide-index-js": () => import("./../../../src/pages/styleguide/index.js" /* webpackChunkName: "component---src-pages-styleguide-index-js" */),
  "component---src-pages-styleguide-typography-js": () => import("./../../../src/pages/styleguide/typography.js" /* webpackChunkName: "component---src-pages-styleguide-typography-js" */),
  "component---src-pages-terms-and-services-js": () => import("./../../../src/pages/terms-and-services.js" /* webpackChunkName: "component---src-pages-terms-and-services-js" */),
  "component---src-pages-thank-you-chat-js": () => import("./../../../src/pages/thank-you-chat.js" /* webpackChunkName: "component---src-pages-thank-you-chat-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-ooa-js": () => import("./../../../src/pages/thank-you-ooa.js" /* webpackChunkName: "component---src-pages-thank-you-ooa-js" */)
}

